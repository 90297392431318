<script setup lang="ts">
const { reviews } = useHome()
</script>

<template>
  <Container
    v-if="reviews?.testimonials"
    class="screen-content py-8 md:py-16 grid md:grid-cols-2 lg:grid-cols-3 px-8 md:px-20 mx-auto gap-12 md:gap-16"
  >
    <ReviewCard
      v-for="item of reviews.testimonials"
      :key="`homepage-rewiew-${item?.person}`"
      :review="item"
      class="last:md:col-span-2 last:lg:col-span-1"
    />
  </Container>
</template>
