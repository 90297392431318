<script setup lang="ts">
import { cx } from 'class-variance-authority'
import { Motion } from 'motion/vue'
import type { AnimationOptions, VariantDefinition } from 'motion'

const { home } = useHome()
const lp = useLocalePath()
const pixelGridLg = [
  '                                    ',
  '  111111111111111111111111111111111111111111111   ',
  ' 1111111111111111     111111211111111111111111    ',
  '  112221111111122111111111221211111111111111111   ',
  '       211111111111111111111111111111    2111111  ',
  '   111111111111111112      111111122111 221111111 ',
  '   11111111111111            1111111111    11111  ',
  '   11  1111111111           111111111111111111121 ',
  '   111  11111111                11111111111111121 ',
  '   111   1111111                 11111111111111   ',
  '   11111111111111111           1111111111111111   ',
  '   111111111111111          1111   1111    1111   ',
  ' 11111111111111                   11111  211111   ',
  '11111   111111111111                11122111111   ',
  ' 11111    111111111          1111111111111111111  ',
  ' 1 11 2111221111111111111    111111111111111111112',
  ' 1211111     111221111111111111111111111111111112 ',
  '   11111  211111111111111111111  11111111111111   ',
].join('\n')

const gradient = 'text-transparent bg-clip-text bg-gradient-to-b'

const initial: VariantDefinition = { y: -16, opacity: 0, rotateX: '-15deg' }
const animate: VariantDefinition = { y: 0, opacity: 1, rotateX: '0deg' }
const transition = (delay = 0): AnimationOptions => ({ easing: [0.16, 1, 0.3, 1], duration: 0.5, delay })
</script>

<template>
  <Container
    class="screen pointer-events-none inset-0 z-10 mx-auto flex flex-col items-center gap-y-6 px-8 pb-8 pt-32 md:pb-32 lg:pb-8 lg:pt-48 xl:pb-32"
  >
    <Motion
      tag="a"
      href="https://workshop.webamboos.com"
      class="pointer-events-auto"
      :transition="transition(0)"
      :initial="initial"
      :animate="animate"
    >
      <Eyebrow :pill="$t('home.hero.eyebrow.pill')" :label="$t('home.hero.eyebrow.title')" />
    </Motion>

    <Motion :transition="transition(0.1)" :initial="initial" :animate="animate">
      <Heading size="hero" as="h1" class="text-center">
        <span :class="cx(gradient, 'from-gray-950 to-gray-600 px-1')">{{ $t('home.hero.title.top') }}</span
        ><br />
        <span :class="cx(gradient, 'from-main-700 to-main-500 px-1')">{{ $t('home.hero.title.bottom') }}</span>
      </Heading>
    </Motion>

    <Motion :transition="transition(0.2)" :initial="initial" :animate="animate" class="mb-12 max-w-2xl">
      <Paragraph size="large" align="center">
        {{ home.hero.body }}
      </Paragraph>
    </Motion>

    <Motion
      v-if="home.hero.action"
      :transition="transition(0.3)"
      :initial="initial"
      :animate="animate"
      class="pointer-events-auto"
    >
      <Action :intent="home.hero.action.intent" :size="home.hero.action.size" as="link" :to="lp('/contact')">
        {{ home.hero.action.label }}
      </Action>
    </Motion>

    <template #outer>
      <PixelGrid class="absolute top-0 z-0 w-full" :pattern="pixelGridLg" />
    </template>
  </Container>
</template>
