<script setup lang="ts">
import { Motion } from 'motion/vue'

const lp = useLocalePath()
const { home } = useHome()
</script>

<template>
  <Motion
    v-if="home.team"
    :initial="{ opacity: 0, y: 64 }"
    :animate="{ opacity: 1, y: 0 }"
    :transition="{ delay: 1, duration: 1, easing: [0.16, 1, 0.3, 1] }"
  >
    <Container
      class="screen-content flex flex-col-reverse items-end px-0 py-16 sm:py-8 md:space-x-0 md:py-16 lg:flex-row"
    >
      <div class="flex w-full flex-col space-y-8 px-8 py-8 md:pl-16 lg:w-1/2 xl:w-1/3">
        <div>
          <Heading as="h2" size="caption" class="mb-2">{{ home.team.title }}</Heading>
          <Heading as="h3" size="medium" class="mb-4">{{ home.team.headline }}</Heading>

          <Paragraph size="medium">
            {{ home.team.body }}
          </Paragraph>
        </div>

        <div v-if="home.team.stats" class="space-y-8">
          <div class="space-y-4">
            <article v-for="stat of home.team.stats" :key="stat.title" class="space-y-1">
              <Heading as="h4" size="small">{{ stat.title }}</Heading>
              <Paragraph size="normal">{{ stat.body }}</Paragraph>
            </article>
          </div>
        </div>

        <div v-if="home.team.action" lass="flex justify-start">
          <Action
            :intent="home.team.action.intent"
            :size="home.team.action.size"
            as="link"
            :to="lp('/company')"
            class="w-fit"
            >{{ home.team.action.label }}</Action
          >
        </div>
      </div>

      <div v-if="home.team.image" class="h-full w-full px-2 lg:w-1/2 lg:pr-4 xl:w-2/3 xl:pr-2">
        <div class="aspect-[16:10] bordershadow-gray rounded-lg bg-gray-50 p-2 lg:w-[clamp(100%,45vw,140%)]">
          <NuxtPicture
            :src="home.team.image.url"
            format="webp"
            fit="cover"
            width="1600"
            height="1000"
            quality="80"
            :alt="home.team.image.alternativeText || home.team.image.name"
            sizes="xs:360px sm:80vw md:50vw lg:800px xl:1600px"
            :img-attrs="{ class: 'w-full h-full rounded bordershadow-main-light object-cover' }"
          />
        </div>
      </div>
    </Container>
  </Motion>
</template>
